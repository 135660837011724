import React, { useEffect } from "react"

import "./account-closure.css"

const AccountClosure = () => {
  useEffect(() => {
    if (window) {
      console.log("Ready to print")
      window.status = "ready_to_print"
    }
  }, [])

  return (
    <div className="account-closure">
      <div>
        <div
          style={{
            width: "100%",
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ width: "calc(100% - 300px)", display: "inline-block" }}>
            <img
              src={require("./blue-bunch-logo.png")}
              style={{ maxWidth: 200 }}
            />
          </div>
          <div
            style={{
              width: "300px",
              marginBottom: 20,
              display: "inline-block",
            }}
          >
            <div>
              <div>Need help?</div>
              <div>E: hello@the-bunch.co.uk</div>
              <div>T: 0333 358 3377</div>
              <div>W: the-bunch.co.uk</div>
            </div>
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: 20 }}>
          <div
            style={{
              width: "50%",
              textAlign: "left",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <div>Bunch</div>
            <div>Redbrick House</div>
            <div>6 York Court</div>
            <div>Bristol</div>
            <div>BS2 8QH</div>
          </div>
          <div
            style={{
              width: "50%",
              textAlign: "left",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <div>Account Address: XXXXX</div>
            <div>Statement Number: XXXXX</div>
            <div>Payment Card Ending: XXXX</div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 23,
          }}
        >
          Your Final Bill
        </div>
        <hr />
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: "bold" }}>Charge period:</span> XXX to XXX
        </div>
        <table style={{ width: "100%", marginBottom: 30 }}>
          <tr>
            <th>Services</th>
            <th>Dates</th>
            <th>Reads</th>
            <th>Usage x Unit Rate</th>
            <th>Standing Charge Total</th>
          </tr>
          <tr>
            <td>Gas</td>
            <td>XX/XX/XXXX</td>
            <td>XXXXX</td>
            <td>XXXX kWh x XXXp XXXp</td>
            <td>£XXX</td>
          </tr>
          <tr>
            <td>Electric</td>
            <td>XX/XX/XXXX</td>
            <td>XXXXX</td>
            <td>XXXX kWh x XXXp XXXp</td>
            <td>£XXX</td>
          </tr>
          <tr>
            <td>Water</td>
            <td>XX/XX/XXXX</td>
            <td>XXXXX</td>
            <td>XXXX kWh x XXXp XXXp</td>
            <td>£XXX</td>
          </tr>
        </table>
        <div style={{ fontWeight: "bold", marginBottom: 10 }}>Household</div>
        <table style={{ width: "100%", marginBottom: 30 }}>
          <tr>
            <td>Total household bills (monthly price x contract length)</td>
            <td>£XXX</td>
          </tr>
          <tr>
            <td>Total household usage (total service usage) </td>
            <td>£XXX</td>
          </tr>
          <tr>
            <td>Total household balance</td>
            <td>£XXX</td>
          </tr>
        </table>
        <div style={{ fontWeight: "bold", marginBottom: 10 }}>Individual</div>
        <table style={{ width: "100%", marginBottom: 30 }}>
          <tr>
            <td>
              Previous outstanding balance (missed payments + late payment fees)
            </td>
            <td>£XXX</td>
          </tr>
          <tr>
            <td>Bonus Bunch rewards</td>
            <td>£XXX</td>
          </tr>
        </table>
        <div style={{ width: "100%", textAlign: "right" }}>
          <div>(+ means you are owed this final balance)</div>
          <div>(- means you owe this this final balance)</div>
        </div>
      </div>
      <div style={{ pageBreakAfter: "always" }} />
      <div>
        FAQs I am owed money - how do I receive this? This money will be paid
        into the account that you have been using to pay for your bills this
        year and can take between 7-14 days, depending on your bank. I owe money
        - how do I pay? You will be sent an invoice which will need to be paid
        within 14 days. After this period, your account will be charged
        automatically using the card details you have been using to pay for your
        bills this year. What if I can’t pay? Whilst you are liable to pay the
        overusage amount, we understand that life can throw you curveballs.
        Please get in contact ASAP using the details at the top of this bill if
        you think your payment will not go through.
      </div>
    </div>
  )
}

export default AccountClosure
